$border-width: 2px;
$arrow-size: 14px;
$offset: 8px;

.tooltip-root {
  @apply border-primary border-2 p-4 bg-white shadow-md z-40 max-w-md;
}

.arrow:after {
  content: '';
  border-top: $border-width solid theme('colors.primary');
  border-left: $border-width solid theme('colors.primary');
  background-color: theme('colors.white');
  position: absolute;
  width: $arrow-size;
  height: $arrow-size;
  transform: rotate(45deg);
}

[data-popper-placement^='top'] > .arrow {
  bottom: calc($offset - $border-width);
  left: calc(-1 * $arrow-size / 2) !important;

  &::after {
    transform: rotate(-135deg);
  }
}

[data-popper-placement^='bottom'] > .arrow {
  top: -$offset;
  left: calc(-1 * $arrow-size / 2) !important;
}

[data-popper-placement^='left'] > .arrow {
  top: calc(-1 * $arrow-size / 2) !important;
  right: calc($offset - $border-width);

  &::after {
    transform: rotate(135deg);
  }
}

[data-popper-placement^='right'] > .arrow {
  top: calc(-1 * $arrow-size / 2) !important;
  left: -$offset;

  &::after {
    transform: rotate(-45deg);
  }
}
